import React, { useEffect, useState } from "react";
import {
    Link
} from "react-router-dom";
import lottie from "lottie-web";
import * as twitterAnimation from "../../lottie/twitter.json";
import * as linkedInAnimation from "../../lottie/linkedin.json";
import * as mailAnimation from "../../lottie/mail.json";
import * as pdfAnimation from "../../lottie/folder.json";
import * as menuAnimation from "../../lottie/menuV2.json";
import "./Nav.scss";

interface Route {
    name: string;
    route: string;
}

const Nav = (props: {
    vertical: boolean,
    showName: boolean,
    smallScreen: boolean,
    toggleMenu?: Function
}) => {
    const routes: Route[] = [
        {
            name: "Awards, Grants & Fellowships",
            route: "/awards",
        },
        {
            name: "Presentations",
            route: "/presentations",
        },
        // {
        //     name: "Research",
        //     route: "/research",
        // },
        {
            name: "Service",
            route: "/service",
        },
        {
            name: "Teaching",
            route: "/teaching",
        },
    ];

    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector("#twitterLogo")!,
            animationData: twitterAnimation,
            renderer: "svg",
            autoplay: false,
            loop: true,
            name: "twitter"
        });
        lottie.loadAnimation({
            container: document.querySelector("#liLogo")!,
            animationData: linkedInAnimation,
            renderer: "svg",
            autoplay: false,
            loop: true,
            name: "li"
        });
        lottie.loadAnimation({
            container: document.querySelector("#mailLogo")!,
            animationData: mailAnimation,
            renderer: "svg",
            autoplay: false,
            loop: false,
            name: "mail"
        });
        lottie.loadAnimation({
            container: document.querySelector("#pdfLogo")!,
            animationData: pdfAnimation,
            renderer: "svg",
            autoplay: false,
            loop: false,
            name: "pdf"
        });
        lottie.loadAnimation({
            container: document.querySelector("#menu")!,
            animationData: menuAnimation,
            renderer: "svg",
            autoplay: false,
            loop: false,
            name: "menu"
        });
    }, []);

    const toggleMenu = (open: boolean) => {
        setMenuOpen(open);
        lottie.setDirection(!open ? -1 : 1);
        lottie.play("menu");
        props.toggleMenu!(open);
    };

    return (
        <>
            {
                props.smallScreen &&
                <>
                    <div className="h-16 flex items-center justify-between p-4 relative shadow-lg">
                        <Link to={"/"} className="link-underline link-underline-black">
                            <p className="tracking-widest text-2xl">
                                KH
                            </p>
                        </Link>
                        <div id="menu" className="h-8 w-8"
                            onClick={() => toggleMenu(!menuOpen)}
                        ></div>
                    </div>
                    <div className={`absolute z-20 top-16 bg-white w-full transition-menu h-0 ${menuOpen ? "open-menu" : ""}`}>
                        {
                            routes.map(l =>
                                <Link to={l.route} key={l.name}
                                    onClick={(e) => {
                                        if (!menuOpen)
                                            return e.preventDefault();
                                        else
                                            toggleMenu(false);
                                    }}
                                    className={`link-underline link-underline-black -translate-x-96 transition-link ${menuOpen ? "reveal-menu-links" : ""}`}
                                ><p className={`text-center text-lg pt-8 pb-8 transition-link-text opacity-0 ${menuOpen ? "reveal-link-text" : ""}`}>{l.name}</p></Link>
                            )
                        }
                    </div>
                </>
            }
            {
                !props.smallScreen &&
                <div className={`h-full flex items-center ${props.vertical ? "flex-col w-1/5" : " justify-between p-4 w-full relative z-20 shadow-lg"}`}>
                    <Link to={"/"} className="link-underline link-underline-black">
                        <p className="tracking-widest text-2xl">
                            {
                                props.showName
                                    ? "Kimberly Hess"
                                    : <p>KH</p>
                            }
                        </p>
                    </Link>
                    {
                        props.vertical && <div className="grow"></div>
                    }
                    <div className={`flex gap-4 justify-start items-center ${props.vertical ? "flex-col" : ""}`}>
                        {
                            routes.map(l =>
                                <Link to={l.route} key={l.name}
                                    className="link-underline link-underline-black"
                                ><p className="text-center">{l.name}</p></Link>
                            )
                        }
                    </div>
                    {
                        props.vertical && <div className="grow"></div>
                    }
                    {
                        props.vertical &&
                        <div className="flex flex-row gap-2 pb-2">
                            <a onMouseEnter={() => lottie.play("twitter")} onMouseLeave={() => lottie.stop("twitter")}
                                id="twitterLogo"
                                href="https://twitter.com/KimHess22" target={"_blank"} rel="noreferrer" className="cursor-pointer h-6 w-6"
                            >
                            </a>
                            <a onMouseEnter={() => lottie.play("li")} onMouseLeave={() => lottie.stop("li")}
                                id="liLogo"
                                href="https://www.linkedin.com/in/kimberly-hess-99577737/" target={"_blank"} rel="noreferrer" className="cursor-pointer h-6 w-6"
                            >
                            </a>
                            <a onMouseEnter={() => lottie.play("mail")} onMouseLeave={() => lottie.stop("mail")}
                                id="mailLogo"
                                href="mailto:hesske@umich.edu" target={"_blank"} rel="noreferrer" className="cursor-pointer h-6 w-6"
                            >
                            </a>
                            <a onMouseEnter={() => lottie.play("pdf")} onMouseLeave={() => lottie.stop("pdf")}
                                id="pdfLogo"
                                href="https://lsa.umich.edu/content/dam/michigan-lsa/people-update/cv/hesske-01202022-130550-HessCV1.18.22.pdf" target={"_blank"} rel="noreferrer" className="cursor-pointer h-6 w-6"
                            >
                            </a>
                        </div>
                    }
                </div>
            }
        </>
    );
};
export default Nav;