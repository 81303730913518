import React, { useState } from "react";
import { Institute } from "../../const";
import "./Awards.scss"

interface Award {
    id: number;
    name: string;
    date: string;
    institute: string;
    award?: string;
}

const Awards = (props: {
    fancy: boolean,
    smallScreen: boolean
}) => {
    const awards: Award[] = [
        {
            id: 0,
            name: "Department of Sociology Outstanding Graduate Student Instructor Award",
            date: "2022",
            institute: Institute.Michigan
        },
        {
            id: 1,
            name: "Sociology Graduate Student Recognition Award",
            date: "2021",
            institute: Institute.Michigan
        },
        {
            id: 2,
            name: "Sweetland Junior Fellowship",
            date: "2020",
            institute: Institute.Michigan,
            award: "$6,000"
        },
        {
            id: 3,
            name: "Department of Sociology Outstanding Graduate Student Instructor Award",
            date: "2019",
            institute: Institute.Michigan
        },
        {
            id: 4,
            name: "Rackham Conference Travel Grant",
            date: "2018",
            institute: Institute.Michigan,
            award: "$800"
        },
        {
            id: 5,
            name: "International Institute Individual Fellowship",
            date: "2017",
            institute: Institute.Michigan,
            award: "$3,000"
        },
        {
            id: 6,
            name: "Rackham Graduate Student Research Grant",
            date: "2017",
            institute: Institute.Michigan,
            award: "$1,500"
        },
        {
            id: 7,
            name: "Department of Sociology Bodine Distinguished Graduate Fellowship",
            date: "2015-2019",
            institute: Institute.Michigan,
            award: "$92,500"
        },
        {
            id: 8,
            name: "Department of History Hoosier Clio Award for Best Honors Thesis",
            date: "2014",
            institute: Institute.Maryland,
        },
        {
            id: 9,
            name: "Department of History Shipleys of Maryland Award for Best Academic Record",
            date: "2014",
            institute: Institute.Maryland
        },
        {
            id: 10,
            name: "President’s Scholarship",
            date: "2010 - 2014",
            institute: Institute.Maryland,
            award: "$48,000"
        },
    ];

    const [selectedAward, setSelectedAward] = useState<Award | undefined>(awards[0]);

    return (
        <>
            {!props.fancy &&
                <div className="p-4 flex flex-col h-full w-full">
                    <div className="p-8 pb-80 bg-blue rounded-tl-md rounded-tr-md shadow-2xl">
                        <p className="text-2xl tracking-widest text-maize">Fellowships, Awards & Grants</p>
                    </div>
                    <div className="bg-maize ml-3 mr-3 -mt-60 h-full rounded-tl-md rounded-tr-md shadow-xl">
                        <div className="grid grid-cols-6 grid-flow-row overflow-y-scroll gap-4 p-2">

                            {
                                awards.map(a =>
                                    <div className="col-span-3 bg-white rounded-md shadow-md p-2">
                                        <p className="font-thin text-md mb-2">{a.name}</p>
                                        <p className="font-semibold text-sm">Awarded in {a.date}</p>
                                        <p className="font-semibold text-sm">Awarded by {a.institute}</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            }

            {
                props.fancy &&
                <div className="flex flex-row h-full w-full overflow-y-hidden">
                    <div className={`lg:w-1/2 overflow-y-auto ${selectedAward?.institute === Institute.Maryland ? "umd" : "umich"} transition`}>
                        {
                            awards
                                .map(a =>
                                    <>
                                        {
                                            props.smallScreen &&
                                            <div className="flex flex-row gap-4 items-center pl-4">
                                                { a.institute === Institute.Maryland && <img src="umd.svg" alt="" className="h-8 w-8" /> }
                                                { a.institute === Institute.Michigan && <img src="uofm.svg" alt="" className="h-8 w-8" /> }
                                                <p key={a.id}
                                                    onMouseEnter={() => setSelectedAward(a)}
                                                    onMouseLeave={() => setSelectedAward(undefined)}
                                                    className={`text-lg p-4 text-white ${selectedAward?.institute === Institute.Michigan || !selectedAward ? " decoration-maize" : "decoration-black"} tracking-tighter ${selectedAward?.id === a.id ? "underline underline-offset-2 decoration-2" : ""}`}>{a.name}</p>
                                            </div>
                                        }
                                        {
                                            !props.smallScreen &&
                                            <p key={a.id}
                                                onMouseEnter={() => setSelectedAward(a)}
                                                onMouseLeave={() => setSelectedAward(undefined)}
                                                className={`text-lg p-4 text-white ${selectedAward?.institute === Institute.Michigan || !selectedAward ? " decoration-maize" : "decoration-black"} tracking-tighter ${selectedAward?.id === a.id ? "underline underline-offset-2 decoration-2" : ""}`}>{a.name}</p>
                                        }
                                    </>
                                )
                        }
                    </div>
                    {
                        !props.smallScreen &&
                        <div className={`w-1/2 relative ${selectedAward?.institute === Institute.Maryland ? "award-parent-umd" : "award-parent-umich"} flex justify-center`}>
                            <div className="flex flex-col items-center">
                                <p className="text-4xl text-white underline underline-offset-2 pt-4 relative z-20">Awarded in</p>
                                <p className="text-3xl text-white pt-4 relative z-20">{selectedAward?.date ? selectedAward.date : "—"}</p>
                                {
                                    selectedAward?.award &&
                                    <>
                                        <p className="text-lg text-white underline underline-offset-2 pt-4 relative z-20">Amount awarded:</p>
                                        <p className="text-md text-white pt-4 relative z-20">{selectedAward?.award ? selectedAward.award : "—"}</p>
                                    </>
                                }
                            </div>
                            <img src="umd.svg" alt="" className={`h-full w-full absolute top-1/4 ${selectedAward?.institute === Institute.Maryland ? "" : "hidden"}`} />
                            <img src="uofm.svg" alt="" className={`h-full w-full absolute top-1/4 ${selectedAward?.institute === Institute.Michigan ? "" : "hidden"}`} />
                            <div className={`clipper transition ${!!selectedAward ? "clipping" : ""} ${selectedAward?.institute === Institute.Maryland ? "umd" : ""} bg-blue`}></div>
                        </div>
                    }
                </div>
            }
        </>
    );
};
export default Awards;