import React from "react";
import { Institute } from "../../const";
import "./Teaching.scss"

interface Experience {
    name: string;
    institute: Institute;
    descriptions: string[];
};

const Experiences = (exp: Experience, key: number) => {
    return (
        <>
            <div className="col-span-1 row-span-2 flex flex-col gap-4 justify-center" key={key}>
                <div className="h-1 underline bg-black pb-1"></div>
                <div className="flex flex-row gap-4 items-center">
                    {
                        exp.institute === Institute.Michigan &&
                        <img src="michigan_crest.svg" alt="Michigan Crest" className="h-20"/>
                    }
                    <p className="text-xl font-semibold">{exp.name}</p>
                </div>
                <div className="flex flex-col gap-2">
                    {
                        exp.descriptions.map((d, i) =>
                            <p className="text-sm" key={i}>{d}</p>
                        )
                    }
                </div>
            </div>
        </>
    );
};

const Teaching = () => {
    const experiences: Experience[] = [
        {
            name: "Instructor of Record",
            institute: Institute.Michigan,
            descriptions: [
                "Eng125: Defining Nations and Nationalism (LSA FYWR course) (Fall 2020)",
                "Soc305: Introduction to Sociological Theory (Spring 2018, Summer 2020)",
                "Soc310: Sociological Research Methods (Summer 2019)"
            ]
        },
        {
            name: "Graduate Student Instructor (GSI)",
            institute: Institute.Michigan,
            descriptions: [
                "Soc100: Introduction to Sociology, Professor Pablo Gaston (Winter 2021)",
                "Soc303: Sociology of Race and Ethnicity, Professor Karyn Lacy (Fall 2018)",
                "Soc240: Sociology of Culture, Professor Fatma Müge Göçek (Fall 2017)",
                "Soc310: Sociological Research Methods, Professor Rachel Best (Fall 2016, Winter 2017)"
            ]
        },
        {
            name: "Undergraduate Research Leader",
            institute: Institute.Michigan,
            descriptions: [
                "Led a team of 5-10 undergraduate Research Assistants through the Sociology Undergraduate Research Opportunities (SURO) and Sociology Opportunity for Undergraduate Leaders (SOUL) programs (Fall 2019, Winter 2020, Fall 2020, Winter 2021, Fall 2021, Winter 2022)",
                "RAs facilitated my dissertation data collection and analysis, while conducting their own secondary literature research and learning the ins and outs of the research process"
            ]
        },
        {
            name: "Pedagogical Training",
            institute: Institute.Michigan,
            descriptions: [
                "Participated in the Sweetland Fellows Seminar on teaching writing (Winter 2020)",
                "Participated in the CRLT Race and Ethnicity Learning Community (Fall 2019)",
                "Completed the 5-week CRLT Preparing Future Faculty Seminar (Spring 2018)",
                "Obtained the CRLT Graduate Teacher Certificate (Spring 2018)"
            ]
        },
        {
            name: "Graduate Student Mentor (GSM)",
            institute: Institute.Michigan,
            descriptions: [
                "Led weekly meetings of Soc993: Graduate Student Instructor Training and conducted Midterm Student Feedback Consultations for new GSIs (Fall 2019, Winter 2020)",
                "Served as a resource and support for all GSIs in the department (Fall 2019-Winter 2020)"
            ]
        },
        {
            name: "Course Development",
            institute: Institute.Michigan,
            descriptions: [
                "Created original syllabi and course materials for Soc305: Introduction to Sociological Theory (in-person and online), Soc310: Sociological Research Methods, Soc993: Graduate Student Instructor Training, and English 125: Defining Nations and Nationalism",
                "Created syllabus with Professor Fatma Müge Göçek for Soc240: Sociology of Culture: From Kennedys to Kardashians"
            ]
        },
    ];

    return (
        <>
            <div className="h-full w-full flex flex-col overflow-y-auto bg-beige">
                <div className="h-20 mt-12 mb-12 min-h-1/4 flex justify-center items-center">
                    <p className="text-xl tracking-wide w-1/2">
                        I am committed to education and have experience teaching in many capacities.
                    </p>
                </div>
                <div className="lg:h-3/4 md:h-1/2 sm:h-1/2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 grid-flow-row p-4 gap-x-8 gap-y-24 items-start pb-12">
                    {
                        experiences.map((exp, i) => Experiences(exp, i))
                    }
                </div>
            </div>
        </>
    );
};
export default Teaching;