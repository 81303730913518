import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import './App.scss';
import * as comp from "./components";

const NavWrapper = (el: JSX.Element, smallScreen: boolean) => {
  return (
    <>
      {
        !smallScreen &&
        <div className="h-16 flex">
          <comp.Nav vertical={false} showName={true} smallScreen={smallScreen} />
        </div>
      }
      {el}
    </>
  );
};

function App() {

  const [smallScreen, setSmallScreen] = useState<boolean>(window.matchMedia("(max-width: 768px)").matches);

  useEffect(() => {
    const handler = (e: any) => setSmallScreen(e.matches);
    window.matchMedia("(max-width: 768px)").addEventListener('change', handler);
    return () => window.removeEventListener("change", handler);
  }, []);


  const handleToggleMenu = () => {
  };

  return (
    <>
      {
        !smallScreen &&
        <Router>
          <div className="h-screen w-screen max-h-screen flex flex-col">
            <Routes>
              <Route path="/" element={<comp.Home smallScreen={false} />} />
              <Route path="/awards" element={
                <>
                  <div className="h-16 flex">
                    <comp.Nav vertical={false} showName={true} smallScreen={smallScreen} />
                  </div>
                  <comp.Awards fancy={true} smallScreen={smallScreen} />
                </>
              } />
              <Route path="/nf/awards" element={
                <>
                  <div className="h-16 flex">
                    <comp.Nav vertical={false} showName={true} smallScreen={smallScreen} />
                  </div>
                  <comp.Awards fancy={false} smallScreen={smallScreen} />
                </>
              } />
              {/* <Route path="/research" element={NavWrapper(<comp.Research />, smallScreen)} /> */}
              <Route path="/presentations" element={NavWrapper(<comp.Presentations />, smallScreen)} />
              <Route path="/service" element={NavWrapper(<comp.ServiceB />, smallScreen)} />
              <Route path="/teaching" element={NavWrapper(<comp.Teaching />, smallScreen)} />
              <Route path="*" element={NavWrapper(<comp.FourOhFour />, smallScreen)} />
            </Routes>
          </div>
        </Router>
      }
      {
        smallScreen &&
        <Router>
          <div className="lg:h-full w-full flex flex-col relative">
            <comp.Nav vertical={false} showName={false} toggleMenu={handleToggleMenu} smallScreen={true} ></comp.Nav>
            <Routes>
              <Route path="/" element={<comp.Home smallScreen={true} />} />
              <Route path="/awards" element={
                <>
                  {/* <div className="h-16 flex">
                    <comp.Nav vertical={false} showName={true} smallScreen={smallScreen}/>
                  </div> */}
                  <comp.Awards fancy={true} smallScreen={smallScreen} />
                </>
              } />
              <Route path="/nf/awards" element={
                <>
                  <div className="h-16 flex">
                    <comp.Nav vertical={false} showName={true} smallScreen={smallScreen} />
                  </div>
                  <comp.Awards fancy={false} smallScreen={smallScreen} />
                </>
              } />
              {/* <Route path="/research" element={NavWrapper(<comp.Research />, smallScreen)} /> */}
              <Route path="/presentations" element={NavWrapper(<comp.Presentations />, smallScreen)} />
              <Route path="/service" element={NavWrapper(<comp.ServiceB />, smallScreen)} />
              <Route path="/teaching" element={NavWrapper(<comp.Teaching />, smallScreen)} />
              <Route path="*" element={NavWrapper(<comp.FourOhFour />, smallScreen)} />
            </Routes>
          </div>
        </Router>
      }
    </>
  );
}

export default App;
