export enum Institute {
    Michigan = "University of Michigan",
    Maryland = "University of Maryland",
};

// School and Beyond (GSB) Series Co-chair (Winter 2020, Fall 2020, Winter 2021)
// • Social Theory Workshop Graduate Coordinator (Fall 2020, Winter 2021)
// • Sociology Graduate Student (SGS) Anti-racist Pedagogy Working Group Member (Fall
// 2020, Winter 2021)
// • Sociology Graduate Student Buddy (Fall 2016, Fall 2017, Fall 2019, Fall 2020)
// • Sociology Graduate Recruitment Buddy (Winter 2016, Winter 2017, Winter 2020)