import React, { useState } from "react";
import Nav from "../Nav/Nav";
import "./Home.scss"

const Home = (props: {
    smallScreen: boolean
}) => {
    const [loaded, setLoaded] = useState<boolean>(false);

    return (
        <>
            {/* <div className="h-full w-full flex flex-row p-8 items-end gap-8">
                <Nav vertical={true} showName={false} smallScreen={false} />
                <img src="hess.jpg" alt="Kim Hess"
                    className={`shadow-2xl rounded-xl h-full ${!loaded ? "animate-pulse bg-gray-blue" : ""}`}
                    onLoad={() => setLoaded(true)} />
                <div className="flex flex-col h-full gap-4 p-12">
                    <p className="text-2xl">Hi, my name is <span className="decoration-blue underline decoration-4">Kimberly Hess</span> and I am a PhD candidate in sociology at <span className="decoration-maize underline decoration-4">University of Michigan</span>. </p>
                    <p className="text-lg">I use comparative-historical methods to study the culture and
                        politics of <span className="decoration-green underline decoration-2">inclusion</span> and <span className="decoration-orange underline decoration-2">exclusion</span> within states and nations.
                        My disseration considers how social, historical, and regional context affect what is included in contemporary US social studies
                        education and how differences in these inclusions relate to different narratives of American history and national identity.</p>

                    <p className="text-lg">I have been the lead instructor for Intro to Sociological Theory (Soc305), Sociological Research Methods (Soc310),
                        Sociology Graduate Student Instructor Training (Soc993), and a first-year writing course, Defining Nations and Nationalism (Eng125).</p>

                    <p className="text-md">
                        Within the Sociology Department, I have served as the Graduate Student Mentor, an Online Course Consultant, an Anti-racist Pedagogy Course Consultant,
                        a Graduate School and Beyond Series Co-Chair, and the Social Theory Workshop Graduate Coordinator. In recognition of my contributions to the department
                        community, I won the Sociology Graduate Student (SGS) Graduate Student Recognition Award in 2021.
                    </p>

                    <p className="text-sm pt-12">
                        Feel free to <a href="mailto:hesske@umich.edu" className="decoration-maize underline underline-offset-2 decoration-2">reach out</a> to hear more about my research. <br />
                        Follow me on <a href="https://twitter.com/KimHess22" target={"_blank"} rel="noreferrer" className="decoration-blue underline decoration-2 underline-offset-2">Twitter</a> and add me on <a href="https://www.linkedin.com/in/kimberly-hess-99577737/" target={"_blank"} rel="noreferrer" className="decoration-blue underline decoration-2 underline-offset-2">LinkedIn</a>.
                    </p>
                </div>
            </div> */}

            {/* {
                props.smallScreen &&
                <div className="h-full w-full flex flex-row p-8 items-end gap-8">
                    <div className="flex flex-col h-full gap-4 p-12">
                        <p className="text-2xl">Hi, my name is <span className="decoration-blue underline decoration-4">Kimberly Hess</span> and I am a PhD candidate in sociology at <span className="decoration-maize underline decoration-4">University of Michigan</span>. </p>
                        <p className="text-lg">I use comparative-historical methods to study the culture and
                            politics of <span className="decoration-green underline decoration-2">inclusion</span> and <span className="decoration-orange underline decoration-2">exclusion</span> within states and nations.
                            My disseration considers how social, historical, and regional context affect what is included in contemporary US social studies
                            education and how differences in these inclusions relate to different narratives of American history and national identity.</p>

                        <p className="text-lg">I have been the lead instructor for Intro to Sociological Theory (Soc305), Sociological Research Methods (Soc310),
                            Sociology Graduate Student Instructor Training (Soc993), and a first-year writing course, Defining Nations and Nationalism (Eng125).</p>

                        <p className="text-md">
                            Within the Sociology Department, I have served as the Graduate Student Mentor, an Online Course Consultant, an Anti-racist Pedagogy Course Consultant,
                            a Graduate School and Beyond Series Co-Chair, and the Social Theory Workshop Graduate Coordinator. In recognition of my contributions to the department
                            community, I won the Sociology Graduate Student (SGS) Graduate Student Recognition Award in 2021.
                        </p>

                        <p className="text-sm pt-12">
                            Feel free to <a href="mailto:hesske@umich.edu" className="decoration-maize underline underline-offset-2 decoration-2">reach out</a> to hear more about my research. <br />
                            Follow me on <a href="https://twitter.com/KimHess22" target={"_blank"} rel="noreferrer" className="decoration-blue underline decoration-2 underline-offset-2">Twitter</a> and add me on <a href="https://www.linkedin.com/in/kimberly-hess-99577737/" target={"_blank"} rel="noreferrer" className="decoration-blue underline decoration-2 underline-offset-2">LinkedIn</a>.
                        </p>
                    </div>
                </div>
            }
            {
                !props.smallScreen &&
                <div className="h-full w-full flex flex-row p-8 items-end gap-8">
                    <Nav vertical={true} showName={false} smallScreen={false} />
                    <img src="hess.jpg" alt="Kim Hess"
                        className={`shadow-2xl rounded-xl h-full ${!loaded ? "animate-pulse bg-gray-blue" : ""}`}
                        onLoad={() => setLoaded(true)} />
                    <div className="flex flex-col h-full gap-4 p-12">
                        <p className="text-2xl">Hi, my name is <span className="decoration-blue underline decoration-4">Kimberly Hess</span> and I am a PhD candidate in sociology at <span className="decoration-maize underline decoration-4">University of Michigan</span>. </p>
                        <p className="text-lg">I use comparative-historical methods to study the culture and
                            politics of <span className="decoration-green underline decoration-2">inclusion</span> and <span className="decoration-orange underline decoration-2">exclusion</span> within states and nations.
                            My disseration considers how social, historical, and regional context affect what is included in contemporary US social studies
                            education and how differences in these inclusions relate to different narratives of American history and national identity.</p>

                        <p className="text-lg">I have been the lead instructor for Intro to Sociological Theory (Soc305), Sociological Research Methods (Soc310),
                            Sociology Graduate Student Instructor Training (Soc993), and a first-year writing course, Defining Nations and Nationalism (Eng125).</p>

                        <p className="text-md">
                            Within the Sociology Department, I have served as the Graduate Student Mentor, an Online Course Consultant, an Anti-racist Pedagogy Course Consultant,
                            a Graduate School and Beyond Series Co-Chair, and the Social Theory Workshop Graduate Coordinator. In recognition of my contributions to the department
                            community, I won the Sociology Graduate Student (SGS) Graduate Student Recognition Award in 2021.
                        </p>

                        <p className="text-sm pt-12">
                            Feel free to <a href="mailto:hesske@umich.edu" className="decoration-maize underline underline-offset-2 decoration-2">reach out</a> to hear more about my research. <br />
                            Follow me on <a href="https://twitter.com/KimHess22" target={"_blank"} rel="noreferrer" className="decoration-blue underline decoration-2 underline-offset-2">Twitter</a> and add me on <a href="https://www.linkedin.com/in/kimberly-hess-99577737/" target={"_blank"} rel="noreferrer" className="decoration-blue underline decoration-2 underline-offset-2">LinkedIn</a>.
                        </p>
                    </div>
                </div>
            } */}

            <div className="h-full w-full flex flex-row p-8 items-end gap-8">
                { !props.smallScreen && <Nav vertical={true} showName={false} smallScreen={false} />}
                { !props.smallScreen && 
                <img src="hess.jpg" alt="Kim Hess"
                    className={`shadow-2xl rounded-xl h-full ${!loaded ? "animate-pulse bg-gray-blue" : ""}`}
                    onLoad={() => setLoaded(true)} /> }
                <div className="flex flex-col h-full gap-4 p-4 lg:p-12">
                    <p className="text-2xl">Hi, my name is <span className="decoration-blue underline decoration-4">Kimberly Hess</span> and I am a PhD candidate in sociology at <span className="decoration-maize underline decoration-4">University of Michigan</span>. </p>
                    <p className="text-lg">I use comparative-historical methods to study the culture and
                        politics of <span className="decoration-green underline decoration-2">inclusion</span> and <span className="decoration-orange underline decoration-2">exclusion</span> within states and nations.
                        My disseration considers how social, historical, and regional context affect what is included in contemporary US social studies
                        education and how differences in these inclusions relate to different narratives of American history and national identity.</p>

                    <p className="text-lg">I have been the lead instructor for Intro to Sociological Theory (Soc305), Sociological Research Methods (Soc310),
                        Sociology Graduate Student Instructor Training (Soc993), and a first-year writing course, Defining Nations and Nationalism (Eng125).</p>

                        <p className="text-md">
                        Within the Sociology Department, I have served as the Graduate Student Mentor, an Online Course Consultant, an Anti-racist Pedagogy Course Consultant,
                        a Graduate School and Beyond Series Co-Chair, and the Social Theory Workshop Graduate Coordinator. In recognition of my contributions to the department
                        community, I won the Sociology Graduate Student (SGS) Graduate Student Recognition Award in 2021.
                    </p>

                    <p className="text-sm">
                        My interests include: political sociology, cultural sociology, nations and nationalism, boundary-making and inclusion;
sociological theory; research methods; comparative-historical methods.
                    </p>

                    <p className="text-sm pt-4">
                        Feel free to <a href="mailto:hesske@umich.edu" className="decoration-maize underline underline-offset-2 decoration-2">reach out</a> to hear more about my research.
                        Follow me on <a href="https://twitter.com/KimHess22" target={"_blank"} rel="noreferrer" className="decoration-blue underline decoration-2 underline-offset-2">Twitter</a> and add me on <a href="https://www.linkedin.com/in/kimberly-hess-99577737/" target={"_blank"} rel="noreferrer" className="decoration-blue underline decoration-2 underline-offset-2">LinkedIn</a>.
                    </p>
                </div>
            </div>

        </>
    );
};
export default Home;
