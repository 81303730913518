import React, { useEffect } from "react";
import "./ServiceB.scss"
import lottie from "lottie-web";
import * as recruitAnimation from "../../lottie/service/47386-we-are-hiring.json";
import * as friendsAnimation from "../../lottie/service/75531-friends.json";
import * as teamAnimation from "../../lottie/service/41448-online-team-collaboration-animation.json";
import * as businessAnimation from "../../lottie/service/60257-business-analytics.json";
import * as webAnimation from "../../lottie/service/64869-web-design-lottie-animation.json";


const ServiceB = () => {

    useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector("#recruit")!,
            animationData: recruitAnimation,
            renderer: "svg",
            autoplay: false,
            loop: true,
            name: "recruit"
        });
        lottie.loadAnimation({
            container: document.querySelector("#friends")!,
            animationData: friendsAnimation,
            renderer: "svg",
            autoplay: false,
            loop: true,
            name: "friends"
        });
        lottie.loadAnimation({
            container: document.querySelector("#team")!,
            animationData: teamAnimation,
            renderer: "svg",
            autoplay: false,
            loop: true,
            name: "team"
        });
        lottie.loadAnimation({
            container: document.querySelector("#business")!,
            animationData: businessAnimation,
            renderer: "svg",
            autoplay: false,
            loop: true,
            name: "business"
        });
        lottie.loadAnimation({
            container: document.querySelector("#web")!,
            animationData: webAnimation,
            renderer: "svg",
            autoplay: false,
            loop: true,
            name: "web"
        });

        const ob1 = new IntersectionObserver(e => {
            e.forEach(en => {
                if (en.isIntersecting) {
                    lottie.play("recruit");
                    document.querySelector("#recruitDesc")?.classList.add("desc-enter");
                    document.querySelector("#recruitDesc > p")?.classList.add("fade-in");
                } else {
                    lottie.stop("recruit");
                }
            })
        });
        const ob2 = new IntersectionObserver(e => {
            e.forEach(en => {
                if (en.isIntersecting) {
                    lottie.play("business");
                    document.querySelector("#businessDesc")?.classList.add("desc-enter");
                    document.querySelector("#businessDesc > p")?.classList.add("fade-in");
                } else {
                    lottie.stop("business");
                }
            })
        });
        const ob3 = new IntersectionObserver(e => {
            e.forEach(en => {
                if (en.isIntersecting) {
                    lottie.play("team");
                    document.querySelector("#teamDesc")?.classList.add("desc-enter");
                    document.querySelector("#teamDesc > p")?.classList.add("fade-in");
                } else {
                    lottie.stop("team");
                }
            })
        });
        const ob4 = new IntersectionObserver(e => {
            e.forEach(en => {
                if (en.isIntersecting) {
                    lottie.play("friends");
                    document.querySelector("#friendsDesc")?.classList.add("desc-enter");
                    document.querySelector("#friendsDesc > p")?.classList.add("fade-in");
                } else {
                    lottie.stop("friends");
                }
            })
        });
        const ob5 = new IntersectionObserver(e => {
            e.forEach(en => {
                if (en.isIntersecting) {
                    lottie.play("web");
                    document.querySelector("#webDesc")?.classList.add("desc-enter");
                    document.querySelector("#webDesc > p")?.classList.add("fade-in");
                } else {
                    lottie.stop("web");
                }
            })
        });
        // @ts-ignore
        ob1.observe(document.querySelector("#recruit"));
        // @ts-ignore
        ob2.observe(document.querySelector("#businessDesc"));
        // @ts-ignore
        ob3.observe(document.querySelector("#teamDesc"));
        // @ts-ignore
        ob4.observe(document.querySelector("#friendsDesc"));
        // @ts-ignore
        ob5.observe(document.querySelector("#webDesc"));

    }, []);

    return (
        <>
            <div className="h-full w-full overflow-y-scroll bg-maize bg-opacity-20 flex flex-col gap-8">
                <div className="h-full w-full flex flex-col lg:flex-row justify-end items-center">
                    <div className="lg:w-1/3 bg-white lg:h-1/2 m-8 shadow-2xl rounded-md flex items-center justify-center" id="recruitDesc">
                        <p className="text-lg tracking-wide p-8 transition-p opacity-0">I was a Sociology Graduate Recruitment Buddy for <span className="underline decoration-green underline-offset-1 decoration-2">three</span> semesters &mdash; Winter 2016, Winter 2017 and Winter 2020.</p>
                    </div>
                    <div id="recruit" className="h-full relative w-fit">
                    </div>
                </div>
                <div className="h-full w-full flex flex-col lg:flex-row lg:justify-start items-center">
                    <div id="business" className="h-full w-fit relative">
                    </div>
                    <div className="lg:w-1/3 bg-white lg:h-1/2 m-8 shadow-2xl rounded-md flex items-center justify-center" id="businessDesc">
                        <p className="text-lg tracking-wide p-8 transition-p opacity-0">I was a Graduate School and Beyond (GSB) Series Co-chair for <span className="underline decoration-green underline-offset-1 decoration-2">three</span> semesters &mdash; Winter 2020, Fall 2020 and Winter 2021.</p>
                    </div>
                </div>
                <div className="h-full w-full flex flex-col lg:flex-row lg:ustify-end items-center">
                    <div className="lg:w-1/3 bg-white lg:h-1/2 m-8 shadow-2xl rounded-md flex items-center justify-center" id="friendsDesc">
                        <p className="text-lg tracking-wide p-8 transition-p opacity-0">I was a Sociology Graduate Student Buddy for <span className="underline decoration-green underline-offset-1 decoration-2">four</span> semesters &mdash; Fall 2016, Fall 2017, Fall 2019 and Fall 2020.</p>
                    </div>
                    <div id="friends" className="h-full w-fit relative">
                    </div>
                </div>
                <div className="h-full w-full flex flex-col lg:flex-row lg:justify-start items-center">
                    <div id="team" className="h-full w-fit relative">
                    </div>
                    <div className="lg:w-1/3 bg-white lg:h-1/2 m-8 shadow-2xl rounded-md flex items-center justify-center" id="teamDesc">
                        <p className="text-lg tracking-wide p-8 transition-p opacity-0">I was a Sociology Graduate Student (SGS) Anti-racist Pedagogy Working Group Member for <span className="underline decoration-green underline-offset-1 decoration-2">two</span> semesters &mdash; Fall 2020 and Winter 2021.</p>
                    </div>
                </div>
                <div className="h-full w-full flex flex-col lg:flex-row lg:justify-end items-center">
                    <div className="lg:w-1/3 bg-white lg:h-1/2 m-8 shadow-2xl rounded-md flex items-center justify-center" id="webDesc">
                        <p className="text-lg tracking-wide p-8 transition-p opacity-0">I was the Social Theory Workshop Graduate Coordinator for <span className="underline decoration-green underline-offset-1 decoration-2">two</span> semesters &mdash; Fall 2020 and Winter 2021.</p>
                    </div>
                    <div id="web" className="h-full w-fit relative">
                    </div>
                </div>



            </div>
        </>
    );
};
export default ServiceB;