import React from "react";
import "./404.scss";

const FourOhFour = () => {
    return (
        <>
            <div className="h-full w-full flex flex-col items-center justify-center">
                <p className="text-4xl">Uh oh.</p>
                <p className="text-lg">You ended up somewhere that we didn't plan.</p>
            </div>
        </>
    );
};
export default FourOhFour;